
<template>
  <div class="wrap">
<!--
    <div class="home-header">
      <HeaderHome/>
    </div>
-->
    <div class="content">
      <div class="swiper">

        <swiper
            :modules="modules"
            :autoplay="autoplayOptions"
            :pagination="{ clickable:true}"
            :loop="true"
            class="mySwiper"
        >
<!--          <swiper-slide > <a class="link" target="_blank"  href="https://2024finals.shaolingames.com/shaolin-pc/#/" ><img class="swiper-img" src="https://2024finals.shaolingames.com/shaolin-pc/assets/bg-44lOQX0S.jpg" referrerPolicy="no-referrer" ></a></swiper-slide>-->
          <template  v-for="item in matches">
            <swiper-slide  > <view class="link"  @click="windowOpen(item.link)"  ><img class="swiper-img" :src="item.src" referrerPolicy="no-referrer" ></view></swiper-slide>
          </template>
          <template  v-for="item in array">
            <swiper-slide v-if="!item.hide" :key="item.id" > <router-link class="link"  :to="`/news/detail?id=${item.id}`" ><img class="swiper-img" :src="item.src" referrerPolicy="no-referrer" ></router-link></swiper-slide>
          </template>

<!--
          <swiper-slide class="bigWin"><router-link class="link" to="/news/sHkaogong"><img class="kaogongbanner" :src="require('../assets/icon_shanghai2.jpg')" alt=""></router-link></swiper-slide>
-->
        </swiper>
      </div>
      <div class="list">
<!--
        <a class="link bigWin" target="_blank" href="https://2024finals.shaolingames.com/shaolin-pc/#/" >
          <dl class="item " >
            <dt><img src="https://2024finals.shaolingames.com/shaolin-pc/assets/bg-44lOQX0S.jpg" alt=""></dt>
            <dd>
              <p>2024 World Shaolin Kung Fu Championship(2024年世界少林考功总决赛)</p>
            </dd>
          </dl>
        </a>
-->
        <view class="link"   @click="windowOpen(item.link)"  v-for="item in matches" :key="item.link">
          <dl class="item " >
            <dt>
              <img :src="item.src" alt="" referrerPolicy="no-referrer"  >
            </dt>
            <dd>
              <p>{{item.en}}({{item.cn}})</p>
            </dd>
          </dl>
        </view>


        <router-link class="link"  :to="`/news/detail?id=${item.id}`"   v-for="item in array" :key="item.id">
          <dl class="item " >
            <dt>
              <p v-if="item.hot" class="tip"><img src="@/assets/icon-hot.png" class="icon">少林功夫段品制</p>
              <img :src="item.src" alt="" referrerPolicy="no-referrer"  >
            </dt>
            <dd>
              <p>{{item.english}}({{item.chinese}})</p>
            </dd>
          </dl>
        </router-link>
        <router-link class="link bigWin"  to="/news/sHkaogong" >
          <dl class="item " >
            <dt><img :src="require('../assets/icon_shanghai2.jpg')" referrerPolicy="no-referrer"   alt=""></dt>
            <dd>
              <p>2023.12少林段品制考功大会上海站</p>
            </dd>
          </dl>
        </router-link>

      </div>

    </div>
  </div>
<!--  <BaseFooter/>-->

</template>

<script setup>
import BaseFooter from "@/components/FooterHome";
import HeaderHome from "@/components/HeaderIntroduce";
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination, A11y, Autoplay,Navigation } from 'swiper'
import 'swiper/swiper.min.css';
import {matches} from "@/js/match";
import {ref} from "vue";

const swiperOption = {
  // 2前进、后退按钮放到容器的外面
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    hiddenClass: "button-hidden", //隐藏时的class
    disabledClass: "button-disabled", //不可用时的class
  },
};


const modules = [Pagination, A11y, Autoplay,Navigation]

const autoplayOptions = {
  /*
    delay: 2000,
    disableOnInteraction: false,
    loop: false,
    pauseOnMouseEnter: true,
    reverseDirection: true
  */
}

const array=[
  {
    id:16,
    hot:true,
    src:"https://www.kungfuchain.com/share_h5_2/img/kaogong/yixing.png",
    english:"Shaolin Kung Fu Grading Examination (Yixing Station)",
    chinese:"少林功夫段品制考评（宜兴站）"
  },
  {
    id:15,
    hot:true,
    src:"https://kungfuchain.oss-cn-shanghai.aliyuncs.com/v2/images/%E5%B0%91%E6%9E%97%E8%80%83%E5%85%AC.png",
    english:"2024 World Shaolin Kung Fu Championship",
    chinese:"2024年世界少林考功总决赛"
  },
  {
    id:14,
    src:"https://kungfuchain.oss-cn-shanghai.aliyuncs.com/v2/images/%E7%A6%85%E6%AD%A6%E8%80%83%E5%8A%9F.png",
    english:"The 5TH OPEN INTERNATIONAL CHAN WU TRADITIONAL KUNG FU AND MODERN WU SHU CHAMPIONSHIP in 2024",
    chinese:"2024年第五届国际禅武传统功夫竞技武术大赛暨中国文化周"

  },
  {
    id:13,
    src:"https://mmbiz.qpic.cn/mmbiz_jpg/ia9CQWb5wnrBafoLy4TWXibZ9Aic9hDuYBXDjib4hx8kBRf2ic1ibPuPSfhrlnsyaLLMMBPjzflhvSpWeDZ8R8GwOAxA/640?wx_fmt=jpeg&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"The competition regulations of the Third Routine World Cup have been announced!",
    chinese:"第三届套路世界杯竞赛规程公布！（2024 日本横滨）",
    hide:1,
  },

  {
    id:12,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_png/y5MibZJ9ibH2PbSiaG5Cob6aE5PkFXTZnObvuWH0CKE43NovrmJNHiaiaG5b9c8UAbDPuEyhnrgp2IBNsXy6Ej4h2Qw/640?wx_fmt=png&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"Martial Arts Competition: The 5th Beijing International Martial Arts Culture Festival and the 14th Beijing International Martial Arts Invitational Tournament in 2024",
    chinese:"武坛赛事：2024年第五届北京国际武术文化节暨第十四届北京国际武术邀请赛"

  },

  {
    id:11,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_jpg/ZEITLOgVuwMQznkE1yDeDGE9lFg7XG7ZgvtaInMyxBMkmFVvxBbLrF58LsQDLpCaceSy9T5r2ibZibfrkib5otictQ/640?wx_fmt=jpeg&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"2024 10th Macau International Wushu Festival and Macau International Martial Arts Competition Regulations",
    chinese:"2024年第十届澳门国际武术节暨澳门国际武道大赛竞赛规程"
  },

 {
    id:10,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_jpg/D0iaFkV8KYgGRF2SOC78VfQudOvlCaDWpFvlyssoyOFV1kOAhK1QckaF61EPOA4RhLgUUfoedQNYrWcLylYWoWg/640?wx_fmt=jpeg&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"The competition rules for the 2024 California International Wushu Championships and the selection of the U.S. National Teams for Wushu, Sanda, Tai Chi, and Kung Fu have been officially announced!",
    chinese:"2024 加州国际武术锦标赛暨美国国家武术、散打、太极拳和功夫代表队选拔赛 竞赛规程正式公布！",
   hide:1,

 },
  {
    id:9,
    src:"https://mmbiz.qpic.cn/mmbiz_jpg/ia9CQWb5wnrBTicpHGxKeGtibyK8oB9Eogr8vjCFWlgmCqRmDd4bx3a1zOPca3yeBCl9yHKRicdBQzP7lkV81xsW8A/640?wx_fmt=jpeg&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"The Competition Regulations for the 9th World Junior Wushu Championships in 2024 have been released!",
    chinese:"2024年第九届世界青少年武术锦标赛竞赛规程发布！",
    hide:1,

  },

  {
    id:8,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_png/YccCfmmGGBbutuAVnsic3PlMYJpnJWibeKpDqfsx7nrH3bzPesSp00ia9icnJeoy0GHrygV6gOsHOLNib3zibvRJV9aQ/640?wx_fmt=png&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"Practice martial arts, strengthen body and mind, ensure safety — Kung Fu Chain collaborates with Ping An Health Insurance to promote nationwide fitness activities.",
    chinese:"练功夫，强身心，保平安——功夫链与平安健康保险携手推动全民健身运动"
  },
 {
    id:7,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_png/YccCfmmGGBbC2mHVQucCib0R17AibedicotalAPARnicSB4ypXEAMyqLVdTzDSNHCuVHM8OjQULvqEYsDYtiapj6qtg/640?wx_fmt=png&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"Practice martial arts, strengthen body and mind, ensure safety — Kung Fu Chain collaborates with Ping An Health Insurance to promote nationwide fitness activities.",
    chinese:"练功夫，强身心，保平安——功夫链与平安健康保险携手推动全民健身运动"
  },
  {
    id:6,
    src:"https://mmbiz.qpic.cn/sz_mmbiz_jpg/YccCfmmGGBaSl7YwKnYaGumpgzJM8xU6WDsffdcvoenqZUsZneyuWjSoaMkVrxZ4GawCiaZicccxT1DKMQKiaWE7A/640?wx_fmt=jpeg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1",
    english:"The 13th American Kung Fu Tai Chi Magazine International Martial Arts Championship has been successfully completed",
    chinese:"第十三届美国功夫太极杂志社国际武术锦标赛圆满完成"
  },

  {
    id:5,
    src:require("@/assets/news/shaolinwuseng.png"),
    english:"INTERVIEW WITH FORMER SHAOLIN MONK, ZHANG LIPENG",
    chinese:"专访前少林僧人张立鹏",
    hide:1,

  },
  {
    id:4,
    src:require("@/assets/news/huzhuajingying.png"),
    english:"2023 TIGER CLAW ELITE KUNGFUMAGAZINE.COM CHAMPIONSHIP AND KUNG FU TAI CHI DAY",
    chinese:"2023 虎爪精英 KUNGFUMAGAZINE.COM 锦标赛暨功夫太极日"
  },
  {
    id:3,
    src:require("@/assets/news/shaolinchuanqi.png"),
    english:"LEGEND OF SHAOLIN WARRIORS",
    chinese:"少林武士传说"
  },

  {
    id:2,
    src:require("@/assets/news/jinbiaosai.png"),
    english:"2024 TIGER CLAW ELITE KUNGFUMAGAZINE.COM CHAMPIONSHIPS",
    chinese:"2024虎爪精英功夫KUNGFUMAGAZINE.COM锦标赛"
  },

  {
    id:1,
    src:require("@/assets/news/taiji1.png"),
    english:"2024 KUNG FU TAI CHI DAY INFORMATION",
    chinese:"2024功夫太极日信息"
  }
]

const windowOpen=url=>{
   window.open(url,"_blank")
}

const  reverseArray=()=>{
   return [...array].reverse();
}
</script>

<style  lang="scss" scoped>
.kaogongbanner{
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: top center;
  margin-top: 50%;
  transform: translateY(-50%);
}
/*
.swiper-slide{
  position: relative;
}
*/
.swiper-img,.swiper{
  width: 717px;
  height: 750px;
  margin: 0;
  object-fit: cover;
  border-radius: 10px;
}
.swiper-img{
  /*
  position: absolute;
  top: 0;
  left: 50%;
  margin-left:calc(-717px / 2);
  */
  object-fit: fill;
  object-position: top center;
  height: auto;
  min-height: 100%;
}

.link{
  opacity: 1;
}
.hover p{
  color: #ccc;
}
.link:hover p{
  color: #666;
}
.wrap{
  background-color:#fffce4;
  width: 100vw;
  min-height: calc(100vh - 48px);
}
.home-header{
  background-color: #302C6D;
  padding:.5em 0;
  flex-direction: column;
}
.content{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:50px 0;

}
.list{
  //background-color: #be3f36;
  width: 686px;
  height:750px;
  overflow-y: scroll;
  border-radius: 10px;
  margin-left: 24px;
  a:last-of-type .item{
    margin-bottom: 0;
  }
  .item{
    text-decoration: none;
    background: #f8f4d4;
    width: 100%;
    padding: 19px 25px 25px 16px;
    box-sizing: border-box;
    margin-bottom:7px;
    display: flex;
    align-items: start;
    font-size: 20px;
    line-height: 25px;
    color: #000;
    cursor: pointer;
    overflow-x: hidden;
    text-align: left;
    img{
      width: 216px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
      object-position: top center;
    }
    dd{
      padding-top: 13px;
      padding-left: 30px;
    }
    dt{
      font-size: 0;
      position: relative;
      .tip{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        background-color: rgba(255,255,255,.5);
        .icon{
          width: 20px;
          height: auto;
          transform: translateY(3px);
        }

        color: #FFF;
        font-size: 12px;
      }
    }
    .time{
      font-size: 16px;
      background: #292566;
    }
  }
}
@media screen and (max-width: 1450px) {
  .bigWin{
    display: none;
  }
  .swiper-img,.swiper{
    width:50vw;
    height: 50vw;
    object-fit: cover;
  }
  .list{
    width: 45vw;
    height: 50vw;
  }
}
</style>